<template>
  <div
    class="program"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 列表 -->
    <div class="programlist">
      <!-- 上 -->
      <div style="display: flex; margin-bottom: 5px">
        <el-form :model="SerachInput" label-width="100px">
          <div style="display: flex; flex-wrap: wrap; align-items: center">
            <el-form-item label="服务时间">
              <el-date-picker
                v-model="overallForm.time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="工单状态">
              <el-select
                v-model="SerachInput.status"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in opStatus"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="中心类型">
              <el-select
                v-model="SerachInput.centerType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in opCenterType"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="display: flex">
            <el-form-item label="关键字查询">
              <el-select
                v-model="SerachInput.userInfo"
                placeholder="请选择"
                clearable
                @change="userOne"
              >
                <el-option
                  v-for="item in opInfo"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-model="SerachInput.valueInfo"
                placeholder=""
                style="width: 150px"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="是否系统创建" style="margin-left: 25px">
              <el-select
                v-model="SerachInput.sysCreate"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in opSystem"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否删除">
              <el-select
                v-model="SerachInput.delFlag"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="item in opDelFlag"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <img
              src="../../assets/images/query.png"
              alt=""
              @click="imaClick"
              style="
                width: 49px;
                height: 29px;
                margin: 6px;
                margin-top: 5px;
                margin-left: 10px;
              "
            />
          </div>
        </el-form>
      </div>
      <!-- table -->
      <div class="tables">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            prop="workNumber"
            label="工单编号"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="type"
            label="工单类型"
            width="100px"
          ></el-table-column>
          <el-table-column
            prop="mainUniqueId"
            label="通话ID"
            width="170px"
          ></el-table-column>
          <el-table-column
            prop="equipmentNumber"
            label="设备号码"
            width="150px"
          ></el-table-column>
          <el-table-column
            prop="createDate"
            label="创建时间"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="centerTypeCn"
            label="中心类型"
            width="100px"
          ></el-table-column>
          <el-table-column
            prop="createUser"
            label="创建客服编号"
            width="110px"
          ></el-table-column>
          <el-table-column
            prop="finishDate"
            label="完成时间"
            width="160px"
          ></el-table-column>
          <el-table-column
            prop="finishUser"
            label="完成客服编号"
            width="110px"
          ></el-table-column>
          <el-table-column
            prop="status"
            label="工单状态"
            width="120px"
          ></el-table-column>
          <el-table-column
            prop="delFlag"
            label="删除状态"
            width="120px"
          ></el-table-column>
          <el-table-column prop="operation" label="操作">
            <template slot-scope="scope">
              <el-button
                class="details"
                size="mini"
                @click="handleSearch(scope.$index, scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom">
        <div class="bottom-left">
          <div
            style="
              margin-left: 25px;
              margin-bottom: 15px;
              font-size: 16px;
              color: #606266;
            "
          >
            工单数量:{{ totalCount }}个
            <span style="margin-left: 10px"
              >进行中的工单:{{ workOrderNum }}个</span
            >
          </div>
        </div>
        <div class="bottom-right">
          <div class="bottom-fen">
            <!-- 分页 -->
            <div class="pages">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-size="pageSize"
                :current-page="pageCount"
                layout="total, prev, pager, next"
                :total="totalCount"
              >
              </el-pagination>
            </div>
          </div>
          <div class="buttons">
            <el-button @click="exportList">数据导出</el-button>
            <el-button>数据分析</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-tabs :tab-position="tabPosition">
        <el-tab-pane label="工单记录">
          <span slot="label"
            ><img
              src="../../assets/gd1.png"
              alt=""
              style="width: 35%; display: block; margin: 0px auto"
            />
            工单记录</span
          >
          <ul class="history-ul" v-if="isShow">
            <li
              v-for="(item, index) in historyList"
              :key="index"
              style="
                border: 1px solid #d6d4d4;
                margin-bottom: 10px;
                margin-right: 10px;
              "
            >
              <div class="content">{{ item.describe }}</div>
              <div class="suggest">{{ item.type }}</div>
              <span class="times">{{ item.createDate }}</span
              ><span class="person">客服</span
              ><span class="times">{{ item.userName }}</span>
              <span class="person-id">ID</span
              ><span class="times">{{ item.workNumber }}</span>
              <div class="suggest1">{{ item.typeDetail }}</div>
            </li>
          </ul>
          <ul class="history-ul" v-if="isShow1">
            <li
              v-for="(item, index) in historyList"
              :key="index"
              style="
                border: 1px solid #d6d4d4;
                margin-bottom: 10px;
                margin-right: 10px;
              "
            >
              <div class="suggest">{{ item.businessType }}</div>
              <span class="times">{{ item.createDate }}</span
              ><span class="person">客服</span
              ><span class="times">{{ item.userName }}</span>
              <span class="person-id">ID</span
              ><span class="times">{{ item.workNumber }}</span>
              <div class="content">{{ item.describe }}</div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="操作记录">
          <span slot="label"
            ><img
              src="../../assets/cz1.png"
              alt=""
              style="width: 35%; display: block; margin: 0px auto"
            />
            操作记录</span
          >
          <el-table
            :data="tableData1"
            style="width: 100%; margin-top: 10px"
            :show-header="false"
            max-height="400"
          >
            <el-table-column prop="dateTime" label="" width="180">
            </el-table-column>
            <el-table-column prop="content" label="" width="180">
            </el-table-column>
            <el-table-column prop="workNumber" label=""> </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        > -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      isShow: true,
      isShow1: false,
      tabPosition: "left",
      SerachInput: {},
      overallForm: {
        time: [],
      },
      opProduct: [],
      opStatus: [
        {
          code: "",
          name: "全部",
        },
        {
          code: 0,
          name: "进行中",
        },
        {
          code: 1,
          name: "已完成",
        },
      ],
      opDelFlag: [
        {
          code: 1,
          name: "已删除",
        },
        {
          code: 0,
          name: "正常",
        },
      ],
      opInfo: [
        {
          value: 1,
          name: "工单编号",
        },
        {
          value: 2,
          name: "通话ID",
        },
        {
          value: 3,
          name: "设备号码",
        },
        {
          value: 4,
          name: "创建客服",
        },
        {
          value: 5,
          name: "完成客服",
        },
      ],
      opSystem: [
        {
          code: "",
          name: "空",
        },
        {
          code: 0,
          name: "否",
        },
        {
          code: 1,
          name: "是",
        },
      ],
      clientHeight: document.body.clientHeight,
      //分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      connected: [],
      tableData: [],
      formInline: {
        openRescue: "",
        useStatus: "",
      },
      lineId: null,
      radioLei: [],
      dialogVisible: false,
      tableData1: [],
      workOrderNum: "",
      opCenterType: [],
      historyList: [],
    };
  },
  created() {
    this.getList();
    this.serviceTs();
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    userOne() {
      this.SerachInput.valueInfo = "";
    },
    //客服类型的下拉框
    serviceTs() {
      api.smallLei({ dictCode: "servicetype" }).then((res) => {
        // console.log("客服类型：", res.data);
        if (res.data.code === 200) {
          this.opCenterType = res.data.result;
        }
      });
    },
    imaClick() {
      this.getList();
    },
    getList() {
      apiShout
        .getcusOrder({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          startDate:
            this.overallForm.time == null ? "" : this.overallForm.time[0],
          endDate:
            this.overallForm.time == null ? "" : this.overallForm.time[1],
          status: this.SerachInput.status,
          type: this.SerachInput.userInfo,
          value: this.SerachInput.valueInfo,
          centerType: this.SerachInput.centerType,
          sysCreate: this.SerachInput.sysCreate,
          delFlag: this.SerachInput.delFlag,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.workOrderNum = res.data.result.workOrder;
            this.tableData.forEach((ele) => {
              if (ele.delFlag == 0) {
                ele.delFlag = "正常";
              } else {
                ele.delFlag = "已删除";
              }
            });
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    //导出
    exportList() {
      let that = this;
      that
        .$http({
          url: "/too/center/customerWorkOrder/exportWorkOrderList",
          params: {
            startDate:
              this.overallForm.time == null ? "" : this.overallForm.time[0],
            endDate:
              this.overallForm.time == null ? "" : this.overallForm.time[1],
            status: this.SerachInput.status,
            type: this.SerachInput.userInfo,
            value: this.SerachInput.valueInfo,
            centerType: this.SerachInput.centerType,
            sysCreate: this.SerachInput.sysCreate,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          // console.log(res,"查询")
          console.log("导出数据：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "工单查询" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    handleSearch(index, row) {
      console.log("详情：", index, row);
      this.dialogVisible = true;
      if (row.centerType == 1) {
        this.isShow = true;
        this.isShow1 = false;
        //客服
        // //查询用户历史工单
        apiShout.getUserHistory({ customerId: row.customerId }).then((dd) => {
          console.log("客服历史工单:", dd.data);
          if (dd.data.code === 200) {
            //对于日期的处理
            for (let i = 0; i < dd.data.result.length; i++) {
              if (dd.data.result[i].createDate == null) {
                dd.data.result[i].createDate = "";
              } else {
                let a = dd.data.result[i].createDate;
                // let mm = dd.data.result[i].typeDetail.slice(1, -1);
                let ds = new Date(a);
                let y = ds.getFullYear();
                let m = ds.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                let d = ds.getDate();
                d = d < 10 ? "0" + d : d;
                let h = ds.getHours();
                let minute = ds.getMinutes();
                minute = minute < 10 ? "0" + minute : minute;
                let second = ds.getSeconds();
                second = second < 10 ? "0" + second : second;
                dd.data.result[i].createDate =
                  y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
                // dd.data.result[i].typeDetail = mm;
              }
            }
            this.historyList = dd.data.result;
          } else {
            this.$message.error(dd.data.msg);
          }
        });
      } else if (row.centerType == 3) {
        this.isShow = false;
        this.isShow1 = true;
        //400
        apiShout.getFourHistory({ phone: row.equipmentNumber }).then((res) => {
          //console.log('400历史工单:',res.data);
          if (res.data.code == 200) {
            //对于日期的处理
            for (let i = 0; i < res.data.result.length; i++) {
              if (res.data.result[i].createDate == null) {
                res.data.result[i].createDate = "";
              } else {
                let a = res.data.result[i].createDate;
                let ds = new Date(a);
                let y = ds.getFullYear();
                let m = ds.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                let d = ds.getDate();
                d = d < 10 ? "0" + d : d;
                let h = ds.getHours();
                let minute = ds.getMinutes();
                minute = minute < 10 ? "0" + minute : minute;
                let second = ds.getSeconds();
                second = second < 10 ? "0" + second : second;
                res.data.result[i].createDate =
                  y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
              }
            }
            this.historyList = res.data.result;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else if (row.centerType == 2) {
        this.isShow = true;
        this.isShow1 = false;
        apiShout.getUserHistory({ customerId: row.customerId }).then((dd) => {
          console.log("外呼历史工单:", dd.data);
          if (dd.data.code === 200) {
            //对于日期的处理
            for (let i = 0; i < dd.data.result.length; i++) {
              if (dd.data.result[i].createDate == null) {
                dd.data.result[i].createDate = "";
              } else {
                let a = dd.data.result[i].createDate;
                // let mm = dd.data.result[i].typeDetail.slice(1, -1);
                let ds = new Date(a);
                let y = ds.getFullYear();
                let m = ds.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                let d = ds.getDate();
                d = d < 10 ? "0" + d : d;
                let h = ds.getHours();
                let minute = ds.getMinutes();
                minute = minute < 10 ? "0" + minute : minute;
                let second = ds.getSeconds();
                second = second < 10 ? "0" + second : second;
                dd.data.result[i].createDate =
                  y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
                // dd.data.result[i].typeDetail = mm;
              }
            }
            this.historyList = dd.data.result;
          } else {
            this.$message.error(dd.data.msg);
          }
        });
      }
      apiShout.gethand({ mainUniqueId: row.mainUniqueId }).then((aa) => {
        console.log(aa.data);
        if (aa.data.code === 200) {
          this.tableData1 = aa.data.result;
        } else {
          this.$message.error(aa.data.msg);
        }
      });
    },
    //分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.getList();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      this.pageCount = pageCount;
      this.getList();
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/.el-input__icon {
  line-height: 33px;
  height: auto;
}
/deep/.el-date-editor .el-range-separator {
  height: auto;
}
/deep/.el-table th.el-table__cell > .cell {
  font-size: 14px;
  color: #909399;
}
/deep/.el-table td.el-table__cell div {
  color: #606266;
  font-size: 14px;
}
/deep/.el-table .el-table__cell {
  text-align: center;
}
/deep/.el-pager {
  margin-top: 5px;
}
.program {
  position: absolute;
  height: 100%;
  width: 100%;
  // 列表
  .programlist {
    margin-left: 25px;
    margin-right: 40px;
    // 上
    .protop {
      position: relative;
      margin-top: 25px;
      height: auto;
      margin-right: 40px;
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        color: #909399;
        font-size: 14px;
        font-weight: bold;
      }
      // input框
      .el-input__inner {
        border-radius: 0;
        border: 1px solid #e1e1e1;
      }
      // 知识分类
      .knowledge {
        position: absolute;
        top: 3px;
        .el-input {
          width: 190px;
          margin-right: 0px;
        }
      }
      // 播放次数
      .play {
        position: absolute;
        left: 300px;
        top: 3px;
        .el-input {
          width: 134px;
        }
      }
      // 关键词
      .antistop {
        position: absolute;
        left: 700px;
        top: 3px;
        display: flex;
        align-items: center;
        .spanguan {
        }
        .el-input {
          width: 230px;
        }
        .buttons {
          margin-left: 14px;
        }
      }
    }
    // table
    .tables {
      margin-top: 10px;
      .el-table::before {
        height: 0;
      }
      .el-table {
        td,
        th.is-leaf {
          border: none !important;
        }
        tr th {
          font-size: 19px;
          color: #a8a8a8;
          text-align: center;
        }
        tr td {
          text-align: center;
          font-size: 17px;
          color: #a8a8a8;
        }
      }
      .buts {
        width: 36px;
        height: 27px;
      }
      .bu {
        margin-right: 20px;
        margin-left: 20px;
      }
    }
    /deep/ .el-table .el-table__cell {
      padding: 12px 0;
      min-width: 0;
      box-sizing: border-box;
      text-overflow: ellipsis;
      vertical-align: middle;
      position: relative;
      text-align: center;
    }
    /deep/ .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ th.el-table__cell.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none;
    }
    /deep/ .el-table td,
    .el-table th.is-leaf {
      border-bottom: none !important;
    }
    // 总结
    .total {
      display: flex;
      justify-content: space-between;
      margin-right: 0px;
      margin-top: 30px;
      margin-left: 34px;
      p {
        font-size: 15px;
        display: inline-block;
        margin-right: 36px;
        color: #606266;
      }
      .spanto {
        color: #92aca7;
        margin-left: 17px;
        margin-right: 12px;
      }
    }
  }
}
/deep/.el-pager li {
  padding: 0 4px;
  font-size: 13px;
  min-width: 35.5px;
  height: 28px;
  line-height: 15px;
  box-sizing: border-box;
  text-align: center;
}
.bottom {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  margin-top: 10px;
}
.bottom-right {
  float: right;
  margin-right: 20px;
}
.bottom-left {
  position: relative;
  //   display: flex;
}
// 按钮
.buttons {
  display: flex;
  justify-content: flex-end;
  // margin-right: 110px;
  .el-button {
    width: 130px;
    height: 40px;
    line-height: 40px;
    border-radius: 25px;
    margin-top: 20px;
    background: #d78673;
    color: #ffffff;
    font-size: 16px;
    padding: 0px;
  }
}
/deep/.el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
/deep/.el-form-item__label {
  color: #909399;
}
.history-ul {
  height: 350px;
  //background-color: #d78673;
  overflow: auto;
  margin-top: 10px;
}
.suggest {
  width: 80px;
  height: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #92aca7;
  color: #fff;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  margin: 10px;
}
.suggest1 {
  // width: 80px;
  height: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  // background-color: #e5f4f1;
  color: #a8a8a8;
  font-size: 15px;
  text-align: left;
  line-height: 20px;
  margin: 10px;
  padding-bottom: 10px;
  // border-bottom: 1px solid #d6d4d4;
}
.person {
  color: #92aca7;
  font-size: 16px;
  margin: 10px 5px 10px 20px;
}
.person-id {
  color: #92aca7;
  font-size: 16px;
  margin: 10px 5px 10px 20px;
}
.times {
  color: rgb(192, 188, 188);
  font-size: 14px;
}
.content {
  color: rgb(192, 188, 188);
  font-size: 14px;
  margin: 0px 0px 0px 10px;
  //   padding-bottom: 10px;
  //   border-bottom: 1px solid #d6d4d4;
}
/deep/.el-tabs--left .el-tabs__item.is-left {
  text-align: center;
  width: 118px;
  height: 70px;
}
/deep/.el-dialog__header {
  padding: 20px 20px 29px;
}
</style>